import React, { useState, useEffect } from 'react'
import { useStore } from '@nanostores/react'
import { cartData, isCartOpen } from '@stores/cartStore.js'

import Loader from '@components/Loader/Loader.jsx'
import ShoppingBag from '@components/Icons/ShoppingBag.jsx'
const CartCount = React.lazy(() =>
    import('@components/Header/GlobalNav/CartCount.jsx')
)

const NavCartMobile = () => {
    const $isCartOpen = useStore(isCartOpen)
    const $cartData = useStore(cartData || null)
    const [numberOfItems, setNumberOfItems] = useState()

    // // console.log $cartData in NavCart', $cartData)

    useEffect(() => {
        let items = []
        let numItems
        if ($cartData) {
            items = $cartData.lines
            if (items) {
                numItems = items
                    .map((item) => item.node.quantity)
                    .reduce((a, b) => a + b, 0)
            }

            setNumberOfItems(numItems)
        }
    }, [$cartData])

    return (
        <div className="nav-cart relative ml-2 mr-4" onClick={() => {
            isCartOpen.set(!$isCartOpen)
        }}>
            <ShoppingBag width="w-[16px]" />
            {numberOfItems && numberOfItems !== null && numberOfItems !== 0 && numberOfItems !== '0' && numberOfItems !== undefined && numberOfItems !== 'undefined' && typeof $cartData.lines !== 'undefined' && $cartData.lines && $cartData.lines.length > 0 ? (
                <CartCount numberOfItems={numberOfItems} />
            ) : null}
        </div>
    )
}

export default NavCartMobile
